<template>
    <md-select id="timeDelay" v-model="selectedTimeDelay">
        <md-option v-for="item in timeDelayOptions" :key="item.value" :value="item.value">
            {{ item.text }}
        </md-option>
    </md-select>
</template>

<script>
import { TEAM_CUSTOM_ACTION_TIME_DELAY_OPTIONS } from '@/utils/CustomActionConstants';

export default {
    name: 'TimeDelayType',
    props: {
        value: { type: String, default: () => null }
    },
    data() {
        return {
            selectedTimeDelay: null,
            timeDelayOptions: TEAM_CUSTOM_ACTION_TIME_DELAY_OPTIONS
        };
    },
    watch: {
        value(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.selectedTimeDelay = newVal;
            }
        },
        selectedTimeDelay(newVal, oldVal) {
            this.$emit('changed', newVal);
        }
    },
    mounted() {
        this.selectedTimeDelay = this.value;
    }
};
</script>
