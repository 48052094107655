<template>
    <md-table class="custom-paginated-table">
        <md-table-row>
            <md-table-head>Event Trigger</md-table-head>
            <md-table-head>Request Quote</md-table-head>
            <md-table-head class="actions">Actions</md-table-head>
        </md-table-row>

        <md-table-row v-for="item in customActions" :key="item.teamCustomActionId">
            <md-table-cell>{{ item.eventTriggerName }}</md-table-cell>
            <md-table-cell>
                {{ item.settings.requestQuote }}
            </md-table-cell>
            <md-table-cell align="end" class="action-buttons">
                <md-button
                    title="Update custom action"
                    class="md-warning md-just-icon md-round"
                    @click.stop="$emit('updateClicked', item)"
                    :data-test-id="`btn-update-${item.teamCustomActionId}`"
                >
                    <md-icon>edit</md-icon>
                </md-button>
                <md-button
                    title="Delete custom action"
                    class="md-danger md-just-icon md-round"
                    @click.stop="$emit('deleteClicked', item)"
                    :data-test-id="`btn-delete-${item.teamCustomActionId}`"
                >
                    <md-icon>delete</md-icon>
                </md-button>
            </md-table-cell>
        </md-table-row>
    </md-table>
</template>

<script>
export default {
    name: 'GetQuotesFromCarriersList',
    props: {
        customActions: { type: Array, default: () => [], required: true }
    }
};
</script>
