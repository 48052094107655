var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-select",
    {
      attrs: { id: "timeDelay" },
      model: {
        value: _vm.selectedTimeDelay,
        callback: function ($$v) {
          _vm.selectedTimeDelay = $$v
        },
        expression: "selectedTimeDelay",
      },
    },
    _vm._l(_vm.timeDelayOptions, function (item) {
      return _c(
        "md-option",
        { key: item.value, attrs: { value: item.value } },
        [_vm._v("\n        " + _vm._s(item.text) + "\n    ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }